export const stateTaxData = {
	AL: {
		single: {
			0: {
				percent: 2,
				amount: 0
			},
			500: {
				percent: 4,
				amount: 10
			},
			3000: {
				percent: 5,
				amount: 110
			}
		},
		joint: {
			0: {
				percent: 2,
				amount: 0
			},
			500: {
				percent: 4,
				amount: 20
			},
			3000: {
				percent: 5,
				amount: 220
			}
		},
		household: {
			0: {
				percent: 2,
				amount: 0
			},
			500: {
				percent: 4,
				amount: 10
			},
			3000: {
				percent: 5,
				amount: 110
			}
		}
	},
	AZ: {
		single: {
			0: {
				percent: 2.59,
				amount: 0
			},
			26500: {
				percent: 3.34,
				amount: 686.35
			},
			53000: {
				percent: 4.17,
				amount: 1571.45
			},
			159000: {
				percent: 4.50,
				amount: 5991.65
			}
		},
		joint: {
			0: {
				percent: 2.59,
				amount: 0
			},
			53000: {
				percent: 3.34,
				amount: 1372.7
			},
			106000: {
				percent: 4.17,
				amount: 3142.9
			},
			318000: {
				percent: 4.5,
				amount: 11983.3
			}
		},
		household: {
			0: {
				percent: 2.59,
				amount: 0
			},
			53000: {
				percent: 3.34,
				amount: 1372.7
			},
			106000: {
				percent: 4.17,
				amount: 3142.9
			},
			318000: {
				percent: 4.5,
				amount: 11983.3
			}
		}
	},
	AR: {
		single: {
			0: {
				percent: 0,
				amount: 0
			},
			4500: {
				percent: 2,
				amount: 0
			},
			8900: {
				percent: 4.17,
				amount: 88.00 
			},
			13400: {
				percent: 3.4,
				amount: 223
			},
			22200: {
				percent: 5,
				amount: 522.2
			},
			37200: {
				percent: 6,
				amount: 1272.2
			},
			79300: {
				percent: 6.9,
				amount: 3798.2
			}
		},
		joint: {
			0: {
				percent: 0,
				amount: 0
			},
			4500: {
				percent: 2,
				amount: 0
			},
			8900: {
				percent: 3,
				amount: 88
			},
			13400: {
				percent: 3.4,
				amount: 223
			},
			22200: {
				percent: 5,
				amount: 522.2
			},
			37200: {
				percent: 6,
				amount: 1272.2
			},
			79300: {
				percent: 6.9,
				amount: 3798.2
			}
		},
		household: {
			0: {
				percent: 0,
				amount: 0
			},
			4500: {
				percent: 2,
				amount: 0
			},
			8900: {
				percent: 3,
				amount: 88
			},
			13400: {
				percent: 3.4,
				amount: 223
			},
			22200: {
				percent: 5,
				amount: 522.2
			},
			37200: {
				percent: 6,
				amount: 1272.2
			},
			79300: {
				percent: 6.9,
				amount: 3798.2
			}
		}
	},
	CA: {
		single: {
			0: {
				percent: 1,
				amount: 0
			},
			8809: {
				percent: 2,
				amount: 88.09
			},
			20883: {
				percent: 4,
				amount: 329.57
			},
			32960: {
				percent: 6,
				amount: 812.65
			},
			45753: {
				percent: 8,
				amount: 1580.23
			},
			57824: {
				percent: 9.3,
				amount: 2545.91
			},
			295373: {
				percent: 10.3,
				amount: 24637.97
			},
			354445: {
				percent: 11.3,
				amount: 30722.38
			},
			590742: {
				percent: 12.3,
				amount: 57423.94
			},
			1000000: {
				percent: 13.3,
				amount: 107762.68
			}
		},
		joint: {
			0: {
				percent: 1,
				amount: 0
			},
			17618: {
				percent: 2,
				amount: 176.18
			},
			41766: {
				percent: 4,
				amount: 659.14
			},
			65920: {
				percent: 6,
				amount: 1625.3
			},
			91506: {
				percent: 8,
				amount: 3160.46
			},
			115648: {
				percent: 9.3,
				amount: 5091.82
			},
			590746: {
				percent: 10.3,
				amount: 49275.93
			},
			708890: {
				percent: 11.3,
				amount: 61444.77
			},
			1000000: {
				percent: 12.3,
				amount: 94340.2
			},
			1181484: {
				percent: 13.3,
				amount: 116662.73
			}
		},
		household: {
			0: {
				percent: 1,
				amount: 0
			},
			17629: {
				percent: 2,
				amount: 176.29
			},
			41768: {
				percent: 4,
				amount: 659.07
			},
			53843: {
				percent: 6,
				amount: 1142.07
			},
			66636: {
				percent: 8,
				amount: 1909.65
			},
			78710: {
				percent: 9.3,
				amount: 2875.57
			},
			401705: {
				percent: 10.3,
				amount: 32914.11
			},
			482047: {
				percent: 11.3,
				amount: 41189.33
			},
			803410: {
				percent: 12.3,
				amount: 77503.35
			},
			1000000: {
				percent: 13.3,
				amount: 101683.92
			}
		}
	},
	CO: {
		single: {
			0: {
				percent: 4.63,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 4.63,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 4.63,
				amount: 0
			}
		}
	},

	CT: {
		single: {
			0: {
				percent: 3,
				amount: 0
			},
			10000: {
				percent: 5,
				amount: 300
			},
			50000: {
				percent: 5.5,
				amount: 2300
			},
			100000: {
				percent: 6,
				amount: 5050
			},
			200000: {
				percent: 6.5,
				amount: 11050
			},
			250000: {
				percent: 6.9,
				amount: 14300
			},
			500000: {
				percent: 6.99,
				amount: 31550
			}
		},
		joint: {
			0: {
				percent: 3,
				amount: 0
			},
			20000: {
				percent: 5,
				amount: 600
			},
			100000: {
				percent: 5.5,
				amount: 4600
			},
			200000: {
				percent: 6,
				amount: 10100
			},
			400000: {
				percent: 6.5,
				amount: 22100
			},
			500000: {
				percent: 6.9,
				amount: 28600
			},
			1000000: {
				percent: 6.99,
				amount: 63100
			}
		},
		household: {
			0: {
				percent: 3,
				amount: 0
			},
			16000: {
				percent: 5,
				amount: 480
			},
			80000: {
				percent: 5.5,
				amount: 3680
			},
			160000: {
				percent: 6,
				amount: 8080
			},
			320000: {
				percent: 6.5,
				amount: 17680
			},
			400000: {
				percent: 6.9,
				amount: 22880
			},
			800000: {
				percent: 6.99,
				amount: 50480
			}
		}
	},
	DE: {
		single: {
			2000: {
				percent: 2.2,
				amount: 0
			},
			5000: {
				percent: 3.9,
				amount: 66
			},
			10000: {
				percent: 4.8,
				amount: 261
			},
			20000: {
				percent: 5.2,
				amount: 741
			},
			25000: {
				percent: 5.55,
				amount: 1001
			},
			60000: {
				percent: 6.6,
				amount: 2943.5
			}
		},
		joint: {
			2000: {
				percent: 2.2,
				amount: 0
			},
			5000: {
				percent: 3.9,
				amount: 66
			},
			10000: {
				percent: 4.8,
				amount: 261
			},
			20000: {
				percent: 5.2,
				amount: 741
			},
			25000: {
				percent: 5.55,
				amount: 1001
			},
			60000: {
				percent: 6.6,
				amount: 2943.5
			}
		},
		household: {
			2000: {
				percent: 2.2,
				amount: 0
			},
			5000: {
				percent: 3.9,
				amount: 66
			},
			10000: {
				percent: 4.8,
				amount: 261
			},
			20000: {
				percent: 5.2,
				amount: 741
			},
			25000: {
				percent: 5.55,
				amount: 1001
			},
			60000: {
				percent: 6.6,
				amount: 2943.5
			}
		}
	},
	GA: {
		single: {
			0: {
				percent: 1,
				amount: 0
			},
			750: {
				percent: 2,
				amount: 7.50
			},

			

			2250: {



				percent: 3,



				amount: 37.5



			},

			

			3750: {



				percent: 4,



				amount: 82.5



			},

			

			5250: {



				percent: 5,



				amount: 142.5



			},

			

			7000: {



				percent: 5.75,



				amount: 230



			}



		},



		joint: {



			0: {



				percent: 1,



				amount: 0



			},

			

			1000: {



				percent: 2,



				amount: 10



			},

			

			3000: {



				percent: 3,



				amount: 50



			},

			

			5000: {



				percent: 4,



				amount: 110



			},

			

			7000: {



				percent: 5,



				amount: 190



			},

			

			10000: {



				percent: 5.75,



				amount: 340



			}



		},



		household: {



			0: {



				percent: 1,



				amount: 0



			},

			

			1000: {



				percent: 2,



				amount: 10



			},

			

			3000: {



				percent: 3,



				amount: 50



			},

			

			5000: {



				percent: 4,



				amount: 110



			},

			

			7000: {



				percent: 5,



				amount: 190



			},

			

			10000: {



				percent: 5.75,



				amount: 340



			}

		}



	},

	HI: {



		single: {



			0: {



				percent: 1.4,



				amount: 0



			},

			

			2400: {



				percent: 3.2,



				amount: 33.6



			},

			

			4800: {



				percent: 5.5,



				amount: 110.4



			},

			

			9600: {



				percent: 6.4,



				amount: 374.4



			},

			

			14400: {



				percent: 6.8,



				amount: 681.6



			},

			

			19200: {



				percent: 7.2,



				amount: 1008



			},

			

			24000: {



				percent: 7.6,



				amount: 1353.6



			},

			

			36000: {



				percent: 7.9,



				amount: 2265.6



			},

			

			48000: {



				percent: 8.25,



				amount: 3213.6



			},

			

			150000: {



				percent: 9,



				amount: 11628.6



			},

			

			175000: {



				percent: 10,



				amount: 13878.6



			},

			

			200000: {



				percent: 11,



				amount: 16378.6



			}



		},



		joint: {



			0: {



				percent: 1.4,



				amount: 0



			},

			

			4800: {



				percent: 3.2,



				amount: 67.2



			},

			

			9600: {



				percent: 5.5,



				amount: 220.8



			},

			

			19200: {



				percent: 6.4,



				amount: 748.8



			},

			

			28800: {



				percent: 6.8,



				amount: 1363.2



			},

			

			38400: {



				percent: 7.2,



				amount: 2016



			},

			

			48000: {



				percent: 7.6,



				amount: 2707.2



			},

			

			72000: {



				percent: 7.9,



				amount: 4531.2



			},

			

			96000: {



				percent: 8.25,



				amount: 6427.2



			},

			

			300000: {



				percent: 9,



				amount: 23257.2



			},

			

			350000: {



				percent: 10,



				amount: 27757.2



			},

			

			400000: {



				percent: 11,



				amount: 32757.2



			}



		},



		household: {



			0: {



				percent: 1.4,



				amount: 0



			},

			

			3600: {



				percent: 3.2,



				amount: 50.4



			},

			

			7200: {



				percent: 5.5,



				amount: 165.6



			},

			

			14400: {



				percent: 6.4,



				amount: 561.6



			},

			

			21600: {



				percent: 6.8,



				amount: 1022.4



			},

			

			28800: {



				percent: 7.2,



				amount: 1512



			},

			

			36000: {



				percent: 7.6,



				amount: 2030.4



			},

			

			54000: {



				percent: 7.9,



				amount: 3398.4



			},

			

			72000: {



				percent: 8.25,



				amount: 4820.4



			},

			

			225000: {



				percent: 9,



				amount: 17442.9



			},

			

			262500: {



				percent: 10,



				amount: 20817.9



			},

			

			300000: {



				percent: 11,



				amount: 24567.9



			}

		}



	},

	ID: {



		single: {



			0: {



				percent: 1.125,



				amount: 0



			},

			

			1541: {



				percent: 3.125,



				amount: 17.34



			},

			

			3081: {



				percent: 3.625,



				amount: 65.46



			},

			

			4622: {



				percent: 4.625,



				amount: 121.32



			},

			

			6162: {



				percent: 5.625,



				amount: 192.55



			},

			

			7703: {



				percent: 6.625,



				amount: 279.23



			},

			

			11554: {



				percent: 6.925,



				amount: 534.36



			}



		},



		joint: {



			0: {



				percent: 1.125,



				amount: 0



			},

			

			3082: {



				percent: 3.125,



				amount: 34.67



			},

			

			6162: {



				percent: 3.625,



				amount: 130.92



			},

			

			9244: {



				percent: 4.625,



				amount: 242.65



			},

			

			12324: {



				percent: 5.625,



				amount: 385.1



			},

			

			15406: {



				percent: 6.625,



				amount: 558.46



			},

			

			23108: {



				percent: 6.925,



				amount: 1068.72



			}



		},



		household: {



			0: {



				percent: 1.125,



				amount: 0



			},

			

			3082: {



				percent: 3.125,



				amount: 34.67



			},

			

			6162: {



				percent: 3.625,



				amount: 130.92



			},

			

			9244: {



				percent: 4.625,



				amount: 242.65



			},

			

			12324: {



				percent: 5.625,



				amount: 385.1



			},

			

			15406: {



				percent: 6.625,



				amount: 558.46



			},

			

			23108: {



				percent: 6.925,



				amount: 1068.72



			}

		}



	},

	IL: {
		single: {
			0: {
				percent: 4.95,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 4.95,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 4.95,
				amount: 0
			}
		}
	},

	IN: {
		single: {
			0: {
				percent: 3.23,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 3.23,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 3.23,
				amount: 0
			}
		}
	},
	IA: {
		single: {
			0: {
				percent: 0.33,
				amount: 0



			},

			

			1638: {



				percent: 0.67,



				amount: 5.41



			},

			

			3276: {



				percent: 2.25,



				amount: 16.38



			},

			

			6552: {



				percent: 4.14,



				amount: 90.09



			},

			

			14742: {



				percent: 5.63,



				amount: 429.16



			},

			

			24570: {



				percent: 5.96,



				amount: 982.47



			},

			

			32760: {



				percent: 6.25,



				amount: 1470.6



			},

			

			49140: {



				percent: 7.44,



				amount: 2494.35



			},

			

			73710: {



				percent: 8.53,



				amount: 4322.35



			}



		},



		joint: {



			0: {



				percent: 0.33,



				amount: 0



			},

			

			1638: {



				percent: 0.67,



				amount: 5.41



			},

			

			3276: {



				percent: 2.25,



				amount: 16.38



			},

			

			6552: {



				percent: 4.14,



				amount: 90.09



			},

			

			14742: {



				percent: 5.63,



				amount: 429.16



			},

			

			24570: {



				percent: 5.96,



				amount: 982.47



			},

			

			32760: {



				percent: 6.25,



				amount: 1470.6



			},

			

			49140: {



				percent: 7.44,



				amount: 2494.35



			},

			

			73710: {



				percent: 8.53,



				amount: 4322.35



			}



		},



		household: {



			0: {



				percent: 0.33,



				amount: 0



			},

			

			1638: {



				percent: 0.67,



				amount: 5.41



			},

			

			3276: {



				percent: 2.25,



				amount: 16.38



			},

			

			6552: {



				percent: 4.14,



				amount: 90.09



			},

			

			14742: {



				percent: 5.63,



				amount: 429.16



			},

			

			24570: {



				percent: 5.96,



				amount: 982.47



			},

			

			32760: {



				percent: 6.25,



				amount: 1470.6



			},

			

			49140: {



				percent: 7.44,



				amount: 2494.35



			},

			

			73710: {



				percent: 8.53,



				amount: 4322.35



			}

		}



	},

	KS: {



		single: {



			0: {



				percent: 3.1,



				amount: 0



			},

			

			15000: {



				percent: 5.25,



				amount: 465



			},

			

			30000: {



				percent: 5.7,



				amount: 1252.5



			}



		},



		joint: {



			0: {



				percent: 3.1,



				amount: 0



			},

			

			30000: {



				percent: 5.25,



				amount: 930



			},

			

			60000: {
				percent: 5.7,
				amount: 2505

			}



		},



		household: {



			0: {



				percent: 3.1,



				amount: 0



			},

			

			15000: {



				percent: 5.25,



				amount: 465



			},

			

			30000: {



				percent: 5.7,



				amount: 1252.5



			}

		}



	},

	KY: {
		single: {
			0: {
				percent: 5,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 5,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 5,
				amount: 0
			}
		}
	},

	LA: {



		single: {



			0: {



				percent: 2,



				amount: 0



			},

			

			12500: {



				percent: 4,



				amount: 250



			},

			

			50000: {



				percent: 6,



				amount: 1750



			}



		},



		joint: {



			0: {



				percent: 2,



				amount: 0



			},

			

			25000: {



				percent: 4,



				amount: 500



			},

			

			100000: {



				percent: 6,



				amount: 3500



			}



		},



		household: {



			0: {



				percent: 2,



				amount: 0



			},

			

			12500: {



				percent: 4,



				amount: 250



			},

			

			50000: {



				percent: 6,



				amount: 1750



			}

		}



	},

	ME: {



		single: {



			0: {



				percent: 5.8,



				amount: 0



			},

			

			22200: {



				percent: 6.75,



				amount: 1287.6



			},

			

			52600: {



				percent: 7.15,



				amount: 3339.6



			}



		},



		joint: {



			0: {



				percent: 5.8,



				amount: 0



			},

			

			44450: {



				percent: 6.75,



				amount: 2578.1



			},

			

			105200: {



				percent: 7.15,



				amount: 6678.73



			}



		},



		household: {



			0: {



				percent: 5.8,



				amount: 0



			},

			

			32750: {



				percent: 6.75,



				amount: 1899.5



			},

			

			77550: {



				percent: 7.15,



				amount: 4923.5



			}

		}



	},

	MD: {



		single: {



			0: {



				percent: 2,



				amount: 0



			},

			

			1000: {



				percent: 3,



				amount: 20



			},

			

			2000: {



				percent: 4,



				amount: 50



			},

			

			3000: {



				percent: 4.75,



				amount: 90



			},

			

			100000: {



				percent: 5,



				amount: 4697.5



			},

			

			125000: {



				percent: 5.25,



				amount: 5947.5



			},

			

			150000: {



				percent: 5.5,



				amount: 7260



			},

			

			250000: {



				percent: 5.75,



				amount: 12760



			}



		},



		joint: {



			0: {



				percent: 2,



				amount: 0



			},

			

			1000: {



				percent: 3,



				amount: 20



			},

			

			2000: {



				percent: 4,



				amount: 50



			},

			

			3000: {



				percent: 4.75,



				amount: 90



			},

			

			150000: {



				percent: 5,



				amount: 7072.5



			},

			

			175000: {



				percent: 5.25,



				amount: 8322.5



			},

			

			225000: {



				percent: 5.5,



				amount: 10947.5



			},

			

			300000: {



				percent: 5.75,



				amount: 15072.5



			}



		},



		household: {



			0: {



				percent: 2,



				amount: 0



			},

			

			1000: {



				percent: 3,



				amount: 20



			},

			

			2000: {



				percent: 4,



				amount: 50



			},

			

			3000: {



				percent: 4.75,



				amount: 90



			},

			

			150000: {



				percent: 5,



				amount: 7072.5



			},

			

			175000: {



				percent: 5.25,



				amount: 8322.5



			},

			

			225000: {



				percent: 5.5,



				amount: 10947.5



			},

			

			300000: {



				percent: 5.75,



				amount: 15072.5



			}

		}



	},

	MA: {
		single: {
			0: {
				percent: 5,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 5,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 5,
				amount: 0
			}
		}
	},
	MI: {
		single: {
			0: {
				percent: 4.25,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 4.25,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 4.25,
				amount: 0
			}
		}
	},
	MN: {
		single: {
			0: {
				percent: 5.35,
				amount: 0
			},
			26960: {
				percent: 6.8,
				amount: 1442.36
			},

			

			88550: {



				percent: 7.85,



				amount: 5630.48



			},

			

			164400: {



				percent: 9.85,



				amount: 11584.71



			}



		},



		joint: {



			0: {



				percent: 5.35,



				amount: 0



			},

			

			39410: {



				percent: 6.8,



				amount: 2108.44



			},

			

			156570: {



				percent: 7.85,



				amount: 10075.32



			},

			

			273470: {



				percent: 9.85,



				amount: 19251.97



			}



		},



		household: {



			0: {



				percent: 5.35,



				amount: 0



			},

			

			32650: {



				percent: 6.8,



				amount: 1746.78



			},

			

			131190: {



				percent: 7.85,



				amount: 8447.5



			},

			

			214980: {



				percent: 9.85,



				amount: 15025.01



			}

		}



	},

	MS: {



		single: {



			1000: {



				percent: 3,



				amount: 0



			},

			

			5000: {



				percent: 4,



				amount: 120



			},

			

			10000: {



				percent: 5,



				amount: 320



			}



		},



		joint: {



			1000: {



				percent: 3,



				amount: 0



			},

			

			5000: {



				percent: 4,



				amount: 120



			},

			

			10000: {



				percent: 5,



				amount: 320



			}



		},



		household: {



			1000: {



				percent: 3,



				amount: 0



			},

			

			5000: {



				percent: 4,



				amount: 120



			},

			

			10000: {



				percent: 5,



				amount: 320



			}

		}



	},

	MO: {



		single: {



			105: {



				percent: 1.5,



				amount: 0



			},

			

			1053: {



				percent: 2,



				amount: 14.22



			},

			

			2106: {



				percent: 2.5,



				amount: 35.28



			},

			

			3159: {



				percent: 3,



				amount: 61.61



			},

			

			4212: {



				percent: 3.5,



				amount: 93.2



			},

			

			5265: {



				percent: 4,



				amount: 130.05



			},

			

			6318: {



				percent: 4.5,



				amount: 172.17



			},

			

			7371: {



				percent: 5,



				amount: 219.56



			},

			

			8424: {



				percent: 5.4,



				amount: 272.21



			}



		},



		joint: {



			105: {



				percent: 1.5,



				amount: 0



			},

			

			1053: {



				percent: 2,



				amount: 14.22



			},

			

			2106: {



				percent: 2.5,



				amount: 35.28



			},

			

			3159: {



				percent: 3,



				amount: 61.61



			},

			

			4212: {



				percent: 3.5,



				amount: 93.2



			},

			

			5265: {



				percent: 4,



				amount: 130.05



			},

			

			6318: {



				percent: 4.5,



				amount: 172.17



			},

			

			7371: {



				percent: 5,



				amount: 219.56



			},

			

			8424: {



				percent: 5.4,



				amount: 272.21



			}



		},



		household: {



			105: {



				percent: 1.5,



				amount: 0



			},

			

			1053: {



				percent: 2,



				amount: 14.22



			},

			

			2106: {



				percent: 2.5,



				amount: 35.28



			},

			

			3159: {



				percent: 3,



				amount: 61.61



			},

			

			4212: {



				percent: 3.5,



				amount: 93.2



			},

			

			5265: {



				percent: 4,



				amount: 130.05



			},

			

			6318: {



				percent: 4.5,



				amount: 172.17



			},

			

			7371: {



				percent: 5,



				amount: 219.56



			},

			

			8424: {



				percent: 5.4,



				amount: 272.21



			}

		}



	},

	MT: {



		single: {



			0: {



				percent: 1,



				amount: 0



			},

			

			3100: {



				percent: 2,



				amount: 31



			},

			

			5400: {



				percent: 3,



				amount: 77



			},

			

			8200: {



				percent: 4,



				amount: 161



			},

			

			11100: {



				percent: 5,



				amount: 277



			},

			

			14300: {



				percent: 6,



				amount: 437



			},

			

			18400: {



				percent: 6.9,



				amount: 683



			}



		},



		joint: {



			0: {



				percent: 1,



				amount: 0



			},

			

			3100: {



				percent: 2,



				amount: 31



			},

			

			5400: {



				percent: 3,



				amount: 77



			},

			

			8200: {



				percent: 4,



				amount: 161



			},

			

			11100: {



				percent: 5,



				amount: 277



			},

			

			14300: {



				percent: 6,



				amount: 437



			},

			

			18400: {



				percent: 6.9,



				amount: 683



			}



		},



		household: {



			0: {



				percent: 1,



				amount: 0



			},

			

			3100: {



				percent: 2,



				amount: 31



			},

			

			5400: {



				percent: 3,



				amount: 77



			},

			

			8200: {



				percent: 4,



				amount: 161



			},

			

			11100: {



				percent: 5,



				amount: 277



			},

			

			14300: {



				percent: 6,



				amount: 437



			},

			

			18400: {



				percent: 6.9,



				amount: 683



			}

		}



	},

	NE: {



		single: {



			0: {



				percent: 2.46,



				amount: 0



			},

			

			3230: {



				percent: 3.51,



				amount: 79.46



			},

			

			19330: {



				percent: 5.01,



				amount: 644.57



			},

			

			31160: {



				percent: 6.84,



				amount: 1237.25



			}



		},



		joint: {



			0: {



				percent: 2.46,



				amount: 0



			},

			

			6440: {



				percent: 3.51,



				amount: 158.42



			},

			

			38680: {



				percent: 5.01,



				amount: 1290.05



			},

			

			62320: {



				percent: 6.84,



				amount: 2474.41



			}							



		},



		household: {



			0: {



				percent: 2.46,



				amount: 0



			},

			

			6020: {



				percent: 3.51,



				amount: 148.09



			},

			

			30940: {



				percent: 5.01,



				amount: 1022.78



			},

			

			46200: {



				percent: 6.84,



				amount: 1787.31



			}

		}



	},

	NH: {



		single: {



			0: {



				percent: 0,



				amount: 0



			}



		},



		joint: {



			0: {



				percent: 5,



				amount: 0



			}



		},



		household: {



			0: {



				percent: 5,



				amount: 0



			}

		}



	},

	NJ: {



		single: {



			0: {



				percent: 1.4,



				amount: 0



			},

			

			20000: {



				percent: 1.75,



				amount: 280



			},

			

			35000: {



				percent: 3.5,



				amount: 542.5



			},

			

			40000: {



				percent: 5.525,



				amount: 717.5



			},

			

			75000: {



				percent: 6.37,



				amount: 2651.25



			},

			

			500000: {



				percent: 8.97,



				amount: 29723.75



			},

			

			5000000: {



				percent: 10.75,



				amount: 433373.75



			}



		},



		joint: {



			0: {



				percent: 1.4,



				amount: 0



			},

			

			20000: {



				percent: 1.75,



				amount: 280



			},

			

			50000: {



				percent: 2.45,



				amount: 805



			},

			

			70000: {



				percent: 3.5,



				amount: 1295



			},

			

			80000: {



				percent: 5.525,



				amount: 1645



			},

			

			150000: {



				percent: 6.37,



				amount: 5512.5



			},

			

			500000: {



				percent: 8.97,



				amount: 27807.5



			},

			

			5000000: {



				percent: 10.75,



				amount: 431457.5



			}									



		},



		household: {



			0: {



				percent: 1.4,



				amount: 0



			},

			

			20000: {



				percent: 1.75,



				amount: 280



			},

			

			50000: {



				percent: 2.45,



				amount: 805



			},

			

			70000: {



				percent: 3.5,



				amount: 1295



			},

			

			80000: {



				percent: 5.525,



				amount: 1645



			},

			

			150000: {



				percent: 6.37,



				amount: 5512.5



			},

			

			500000: {



				percent: 8.97,



				amount: 27807.5



			},

			

			5000000: {



				percent: 10.75,



				amount: 431457.5



			}	

		}



	},

	NM: {



		single: {



			0: {



				percent: 1.7,



				amount: 0



			},

			

			5500: {



				percent: 3.2,



				amount: 93.5



			},

			

			11000: {



				percent: 4.7,



				amount: 269.5



			},

			

			16000: {



				percent: 4.9,



				amount: 504.5



			}



		},



		joint: {



			0: {



				percent: 1.7,



				amount: 0



			},

			

			8000: {



				percent: 3.2,



				amount: 136



			},

			

			16000: {



				percent: 4.7,



				amount: 392



			},

			

			24000: {



				percent: 4.9,



				amount: 768



			}									



		},



		household: {



			0: {



				percent: 1.7,



				amount: 0



			},

			

			8000: {



				percent: 3.2,



				amount: 136



			},

			

			16000: {



				percent: 4.7,



				amount: 392



			},

			

			24000: {



				percent: 4.9,



				amount: 768



			}	

		}



	},

	NY: {



		single: {



			0: {



				percent: 4,



				amount: 0



			},

			

			8500: {



				percent: 4.5,



				amount: 340



			},

			

			11700: {



				percent: 5.25,



				amount: 484



			},

			

			13900: {



				percent: 5.9,



				amount: 599.5



			},

			

			21400: {



				percent: 6.21,



				amount: 1042



			},

			

			80650: {



				percent: 6.49,



				amount: 4721.43



			},

			

			215400: {



				percent: 6.85,



				amount: 13466.7



			},

			

			1077550: {



				percent: 8.82,



				amount: 72523.98



			}



		},



		joint: {



			0: {



				percent: 4,



				amount: 0



			},

			

			17150: {



				percent: 4.5,



				amount: 686



			},

			

			23600: {



				percent: 5.25,



				amount: 976.25



			},

			

			27900: {



				percent: 5.9,



				amount: 1202



			},

			

			43000: {



				percent: 6.09,



				amount: 2092.9



			},

			

			161550: {



				percent: 6.41,



				amount: 9312.6



			},

			

			323200: {



				percent: 6.85,



				amount: 19674.36



			},

			

			2155350: {



				percent: 8.82,



				amount: 145176.64



			}									



		},



		household: {



			0: {



				percent: 4,



				amount: 0



			},

			

			12800: {



				percent: 4.5,



				amount: 512



			},

			

			17650: {



				percent: 5.25,



				amount: 730.25



			},

			

			20900: {



				percent: 5.9,



				amount: 900.88





			},

			

			32200: {



				percent: 6.09,



				amount: 1567.58



			},

			

			107650: {



				percent: 6.41,



				amount: 6162.48



			},

			

			269300: {



				percent: 6.85,



				amount: 16524.25



			},

			

			1616450: {



				percent: 8.82,



				amount: 108804.02



			}	

		}



	},

	NC: {
		single: {
			0: {
				percent: 5.25,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 5.25,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 5.25,
				amount: 0
			}
		}
	},
	ND: {
		single: {
			0: {
				percent: 1.1,
				amount: 0
			},
			39450: {
				percent: 2.04,
				amount: 433.95
			},
			95500: {
				percent: 2.27,
				amount: 1577.37
			},
			199250: {
				percent: 2.64,
				amount: 3932.5
			},

			

			433200: {



				percent: 2.9,



				amount: 10108.78



			}



		},



		joint: {



			0: {



				percent: 1.1,



				amount: 0



			},

			

			65900: {



				percent: 2.04,



				amount: 724.9



			},

			

			159200: {



				percent: 2.27,



				amount: 2628.22



			},

			

			242550: {



				percent: 2.64,



				amount: 4520.27



			},

			

			433200: {
				percent: 2.9,
				amount: 9553.43
			}									



		},



		household: {



			0: {



				percent: 1.1,



				amount: 0



			},

			

			52850: {



				percent: 2.04,



				amount: 581.35



			},

			

			136450: {



				percent: 2.27,



				amount: 2286.79



			},

			

			220900: {



				percent: 2.64,



				amount: 4203.81



			},

			

			433200: {



				percent: 2.9,



				amount: 9808.53



			}	

		}



	},

	OH: {



		single: {



			21750: {



				percent: 2.85,



				amount: 0



			},

			

			43450: {



				percent: 3.326,



				amount: 618.45



			},

			

			86900: {



				percent: 3.802,



				amount: 2063.6



			},

			

			108700: {



				percent: 4.413,



				amount: 2892.43



			},

			

			217400: {



				percent: 4.797,



				amount: 7689.36



			}



		},



		joint: {



			21750: {



				percent: 2.85,



				amount: 0



			},

			

			43450: {



				percent: 3.326,



				amount: 618.45



			},

			

			86900: {



				percent: 3.802,



				amount: 2063.6



			},

			

			108700: {



				percent: 4.413,



				amount: 2892.43



			},

			

			217400: {



				percent: 4.797,



				amount: 7689.36



			}									



		},



		household: {



			21750: {



				percent: 2.85,



				amount: 0



			},

			

			43450: {



				percent: 3.326,



				amount: 618.45



			},

			

			86900: {



				percent: 3.802,



				amount: 2063.6



			},

			

			108700: {



				percent: 4.413,



				amount: 2892.43



			},

			

			217400: {



				percent: 4.797,



				amount: 7689.36



			}	

		}



	},

	OK: {



		single: {



			0: {



				percent: 0.5,



				amount: 0



			},

			

			1000: {



				percent: 1,



				amount: 5



			},

			

			2500: {



				percent: 2,



				amount: 20



			},

			

			3750: {



				percent: 3,



				amount: 45



			},

			

			4900: {



				percent: 4,



				amount: 79.5



			},

			

			7200: {



				percent: 5,



				amount: 171.5



			}



		},



		joint: {



			0: {



				percent: 0.5,



				amount: 0



			},

			

			2000: {



				percent: 1,



				amount: 10



			},

			

			5000: {



				percent: 2,



				amount: 40



			},

			

			7500: {



				percent: 3,



				amount: 90



			},

			

			9800: {



				percent: 4,



				amount: 159



			},

			

			12200: {



				percent: 5,



				amount: 255



			}									



		},



		household: {



			0: {



				percent: 0.5,



				amount: 0



			},

			

			2000: {



				percent: 1,



				amount: 10



			},

			

			5000: {



				percent: 2,



				amount: 40



			},

			

			7500: {



				percent: 3,



				amount: 90



			},

			

			9800: {



				percent: 4,



				amount: 159



			},

			

			12200: {



				percent: 5,



				amount: 255



			}		

		}



	},

	OR: {



		single: {



			0: {



				percent: 5,



				amount: 0



			},

			

			3550: {



				percent: 7,



				amount: 177.5



			},

			

			8900: {



				percent: 9,



				amount: 552



			},

			

			125000: {



				percent: 9.9,



				amount: 11001



			}



		},



		joint: {



			0: {



				percent: 5,



				amount: 0



			},

			

			7100: {



				percent: 7,



				amount: 355



			},

			

			17800: {



				percent: 9,



				amount: 1104



			},

			

			250000: {



				percent: 9.9,



				amount: 22002



			}									



		},



		household: {



			0: {



				percent: 5,



				amount: 0



			},

			

			7100: {



				percent: 7,



				amount: 355



			},

			

			17800: {



				percent: 9,



				amount: 1104



			},

			

			250000: {



				percent: 9.9,



				amount: 22002



			}			

		}



	},

	PA: {
		single: {
			0: {
				percent: 3.07,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 3.07,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 3.07,
				amount: 0
			}
		}
	},
	RI: {
		single: {



			0: {



				percent: 3.75,



				amount: 0



			},

			

			65250: {



				percent: 4.75,



				amount: 2446.88



			},

			

			148350: {



				percent: 5.99,



				amount: 6394.13



			}



		},



		joint: {



			0: {



				percent: 3.75,



				amount: 0



			},

			

			65250: {



				percent: 4.75,



				amount: 2446.88



			},

			

			148350: {



				percent: 5.99,



				amount: 6394.13



			}									



		},



		household: {



			0: {



				percent: 3.75,



				amount: 0



			},

			

			65250: {



				percent: 4.75,



				amount: 2446.88



			},

			

			148350: {



				percent: 5.99,



				amount: 6394.13



			}		

		}



	},

	SC: {



		single: {



			0: {



				percent: 0,



				amount: 0



			},

			

			3070: {



				percent: 3,



				amount: 0



			},

			

			6150: {



				percent: 4,



				amount: 92.40



			},

			

			9230: {



				percent: 5,



				amount: 215.6



			},

			

			12310: {



				percent: 6,



				amount: 369.6



			},

			

			15400: {



				percent: 7,



				amount: 555	



			}



		},



		joint: {



			0: {



				percent: 0,



				amount: 0



			},

			

			3070: {



				percent: 3,



				amount: 0



			},

			

			6150: {



				percent: 4,



				amount: 92.40



			},

			

			9230: {



				percent: 5,



				amount: 215.6



			},

			

			12310: {



				percent: 6,



				amount: 369.6



			},

			

			15400: {



				percent: 7,



				amount: 555	



			}								



		},



		household: {



			0: {



				percent: 0,



				amount: 0



			},

			

			3070: {



				percent: 3,



				amount: 0



			},

			

			6150: {



				percent: 4,



				amount: 92.40



			},

			

			9230: {



				percent: 5,



				amount: 215.6



			},

			

			12310: {



				percent: 6,



				amount: 369.6



			},

			

			15400: {



				percent: 7,



				amount: 555	



			}		

		}



	},

	TN: {
		single: {
			0: {
				percent: 1,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 1,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 1,
				amount: 0
			}
		}
	},
	UT: {
		single: {
			0: {
				percent: 4.95,
				amount: 0
			}
		},
		joint: {
			0: {
				percent: 4.95,
				amount: 0
			}
		},
		household: {
			0: {
				percent: 4.95,
				amount: 0
			}
		}
	},
	VT: {



		single: {



			0: {



				percent: 3.35,



				amount: 0



			},

			

			39600: {



				percent: 6.6,



				amount: 1326.6



			},

			

			96000: {



				percent: 7.6,



				amount: 5049



			},

			

			200200: {



				percent: 8.75,



				amount: 12968.2



			}



		},



		joint: {



			0: {



				percent: 3.35,



				amount: 0



			},

			

			66150: {



				percent: 6.6,



				amount: 2216.03



			},

			

			159950: {



				percent: 7.6,



				amount: 8406.83



			},

			

			243750: {



				percent: 8.75,



				amount: 14775.63



			}								



		},



		household: {



			0: {



				percent: 3.35,



				amount: 0



			},

			

			53100: {



				percent: 6.6,



				amount: 1778.85



			},

			

			137050: {



				percent: 7.6,



				amount: 7319.55



			},

			

			221950: {



				percent: 8.75,



				amount: 13771.95



			}	

		}



	},

	VA: {



		single: {



			0: {



				percent: 2,



				amount: 0



			},

			

			3000: {



				percent: 3,



				amount: 60



			},

			

			5000: {



				percent: 5,



				amount: 120



			},

			

			17000: {



				percent: 5.75,



				amount: 720



			}



		},



		joint: {



			0: {



				percent: 2,



				amount: 0



			},

			

			3000: {



				percent: 3,



				amount: 60



			},

			

			5000: {



				percent: 5,



				amount: 120



			},

			

			17000: {



				percent: 5.75,



				amount: 720



			}								



		},



		household: {



			0: {



				percent: 2,



				amount: 0



			},

			

			3000: {



				percent: 3,



				amount: 60



			},

			

			5000: {



				percent: 5,



				amount: 120



			},

			

			17000: {



				percent: 5.75,



				amount: 720



			}	

		}



	},

	WV: {



		single: {



			0: {



				percent: 3,



				amount: 0



			},

			

			10000: {



				percent: 4,



				amount: 300



			},

			

			25000: {



				percent: 4.5,



				amount: 900



			},

			

			40000: {



				percent: 6,



				amount: 1575



			},

			

			60000: {



				percent: 6.5,



				amount: 2775



			}



		},



		joint: {



			0: {



				percent: 3,



				amount: 0



			},

			

			10000: {



				percent: 4,



				amount: 300



			},

			

			25000: {



				percent: 4.5,



				amount: 900



			},

			

			40000: {



				percent: 6,



				amount: 1575



			},

			

			60000: {



				percent: 6.5,



				amount: 2775



			}								



		},



		household: {



			0: {



				percent: 3,



				amount: 0



			},

			

			10000: {



				percent: 4,



				amount: 300



			},

			

			25000: {



				percent: 4.5,



				amount: 900



			},

			

			40000: {



				percent: 6,



				amount: 1575



			},

			

			60000: {



				percent: 6.5,



				amount: 2775



			}	

		}



	},

	WI: {



		single: {



			0: {



				percent: 3.86,



				amount: 0



			},

			

			11760: {



				percent: 5.04,



				amount: 453.94



			},

			

			23520: {



				percent: 6.27,



				amount: 1046.64



			},

			

			258950: {



				percent: 7.65,



				amount: 15808.10



			}



		},



		joint: {



			0: {



				percent: 3.86,



				amount: 0



			},

			

			15680: {



				percent: 5.04,



				amount: 605.25



			},

			

			31360: {



				percent: 6.27,



				amount: 1395.52



			},

			

			345270: {



				percent: 7.65,



				amount: 21077.68

				

			}								



		},



		household: {



			0: {



				percent: 3.86,



				amount: 0



			},

			

			11760: {



				percent: 5.04,



				amount: 453.94



			},

			

			23520: {



				percent: 6.27,



				amount: 1046.64



			},

			

			258950: {



				percent: 7.65,



				amount: 15808.10



			}	

		}



	},

	DC: {



		single: {



			0: {



				percent: 4,



				amount: 0



			},

			

			10000: {



				percent: 6,



				amount: 400



			},

			

			40000: {



				percent: 6.5,



				amount: 2200



			},

			

			60000: {



				percent: 8.5,



				amount: 3500



			},

			

			350000: {



				percent: 8.75,



				amount: 28150



			},

			

			1000000: {



				percent: 8.95,



				amount: 85025





			}



		},



		joint: {



			0: {



				percent: 4,



				amount: 0



			},

			

			10000: {



				percent: 6,



				amount: 400



			},

			

			40000: {



				percent: 6.5,



				amount: 2200



			},

			

			60000: {



				percent: 8.5,



				amount: 3500



			},

			

			350000: {



				percent: 8.75,



				amount: 28150



			},

			

			1000000: {



				percent: 8.95,



				amount: 85025





			}								



		},



		household: {



			0: {



				percent: 4,



				amount: 0



			},

			

			10000: {



				percent: 6,



				amount: 400



			},

			

			40000: {



				percent: 6.5,



				amount: 2200



			},

			

			60000: {



				percent: 8.5,



				amount: 3500



			},

			

			350000: {



				percent: 8.75,



				amount: 28150



			},

			

			1000000: {



				percent: 8.95,



				amount: 85025





			}		

		}



	}

}