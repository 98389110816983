import React, { Component, createContext } from "react";
import store from "store";
import { Spinner } from 'reactstrap';
import { auth } from "../firebase";
import { createOrUpdateUser } from "../api/rest";
import CONSTANTS from "../shared/Constants";

export const UserContext = createContext({ user: null, asUser: null, setAdditionalData: () => { } });

class UserProvider extends Component {
    state = {
        user: null,
        asUser: null,
        additionalData: null,
        isLoading: false,
        propertyMaps: {
            paymentMethods: {},
            sources: {},
            contacts: {}
        },
        asUserPropertyMaps: {
            paymentMethods: {},
            sources: {},
            contacts: {}
        }
    };

    setAdditionalData(additionalData) {
        this.setState({ additionalData });
    }

    setLoading(value) {
        this.setState({ isLoading: value });
    }

    propertiesToMap = (collection) => {
        let map = {};
        if (!collection || collection.length === 0) {
            return map;
        }
        collection.forEach(next => {
            map[next.id] = next.name;
        });
        return map;
    }

    setUser(user) {
        let propertyMaps = {
            paymentMethods: this.propertiesToMap(user?.paymentMethods),
            sources: this.propertiesToMap(user?.sources),
            contacts: this.propertiesToMap(user?.contacts)
        }
        this.setState({ user, propertyMaps });
    }

    setAsUser(asUser) {
        let asUserPropertyMaps = {
            paymentMethods: this.propertiesToMap(asUser?.paymentMethods),
            sources: this.propertiesToMap(asUser?.sources),
            contacts: this.propertiesToMap(asUser?.contacts)
        }
        this.setState({ asUser, asUserPropertyMaps });
    }

    componentDidMount = async () => {
        auth.onAuthStateChanged(async userAuth => {
            if (!userAuth) { // Logged out
                store.set(CONSTANTS.AUTH_TOKEN, null);
                store.set(CONSTANTS.AS_USER, null);
                this.setState({ additionalData: null });
                this.setUser(null);
                return;
            }
            this.setLoading(true);
            let token = await userAuth.getIdToken(true);
            store.set(CONSTANTS.AUTH_TOKEN, token);
            store.set(CONSTANTS.AS_USER, null);
            let user = await createOrUpdateUser(userAuth, this.state.additionalData);
            this.setState({ additionalData: null });
            this.setUser(user);
            this.setAsUser(null);
            this.setLoading(false);
            setInterval(() => {
                userAuth.getIdToken(true).then(async (accessToken) => {
                    console.log("refreshToken=======>", accessToken);
                    store.set(CONSTANTS.AUTH_TOKEN, accessToken);
                });
            }, 50 * 60 * 1000);
            
        });
    };

    render() {
        return (
            <UserContext.Provider value={{
                user: this.state.user,
                asUser: this.state.asUser,
                propertyMaps: this.state.propertyMaps,
                asUserPropertyMaps: this.state.asUserPropertyMaps,
                setAdditionalData: this.setAdditionalData.bind(this),
                setUser: this.setUser.bind(this),
                setAsUser: this.setAsUser.bind(this),
                setLoading: this.setLoading.bind(this)
            }}>
                {this.props.children}
                {
                    this.state.isLoading &&
                        <div className="loading-container">
                            <Spinner color="primary" />
                        </div>
                }
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
