import store from "store";
import CONSTANTS from "../shared/Constants";
import axios from 'axios';
import { auth } from "../firebase";
import { toastError } from '../shared/ToastifyService';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

axiosInstance.interceptors.request.use(function (config) {
    const token = store.get(CONSTANTS.AUTH_TOKEN);
    const asUser = store.get(CONSTANTS.AS_USER);
    config.headers.Authorization = `Bearer ${token}`;
    if (!!asUser) {
        config.headers["X-Auth-AS"] = `${asUser}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error);
    const status = error.response.status;
    if (status === 401) {
        console.log("Not authorized, forcing Firebase sign out...");
        auth.signOut();
    } else if (status === 403 || status === 400) {
        const data = error.response.data;
        const message = data.message || data.errors[0].msg;
        toastError(message);
    } else {
        toastError("Unexpected error occurred!");
    }
    return Promise.reject(error);
});

export default axiosInstance;
