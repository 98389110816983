const genders = [
    { key: "M", value: "Male" },
    { key: "F", value: "Female" },
    { key: "NB", value: "Non-binary" }
];
const defaultGender = genders[0];

const currencies = [
    { key: "AUD", value: "AUD", label: "AUD", iconClasses: ['fas', 'dollar-sign'], symbol: "A$" },
    { key: "CAD", value: "CAD", label: "CAD", iconClasses: ['fas', 'dollar-sign'], symbol: "C$" },
    { key: "GBP", value: "GBP", label: "GBP", iconClasses: ['fas', 'pound-sign'], symbol: "£" },
    { key: "EUR", value: "EUR", label: "EUR", iconClasses: ['fas', 'euro-sign'], symbol: "€" },
    { key: "USD", value: "USD", label: "USD", iconClasses: ['fas', 'dollar-sign'], symbol: "$" }
];
const defaultCurrency = currencies[0];

const stateList = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

const fillingStatusList = [
    {
        key: 'single', value: 'Single'
    },
    {
        key: 'joint', value: 'Married Filing Jointly'
    },
    {
        key: 'household', value: 'Head of Household'
    }
]

const selfEmploymentTaxRate = {
    selfEmployment: 15.3,
    socialSecurity: 12.4,
    medicare: 2.9
}

const federalTaxData = {
    single: {
        0: {
            percent: 10,
            amount: 0
        },
        9875: {
            percent: 12,
            amount: 987.5
        },
        40125: {
            percent: 22,
            amount: 4617.5
        },
        85525: {
            percent: 24,
            amount: 14605.5
        },
        163300: {
            percent: 32,
            amount: 33271.5
        },
        207350: {
            percent: 35,
            amount: 47367.5
        },
        518400: {
            percent: 37,
            amount: 156235
        }
    },
    joint: {
        0: {
            percent: 10,
            amount: 0
        },
        19750: {
            percent: 12,
            amount: 1975
        },
        80250: {
            percent: 22,
            amount: 9235
        },
        171050: {
            percent: 24,
            amount: 29211
        },
        326600: {
            percent: 32,
            amount: 66543
        },
        414700: {
            percent: 35,
            amount: 94735
        },
        622050: {
            percent: 37,
            amount: 167307.5
        }
    },
    household: {
        0: {
            percent: 10,
            amount: 0
        },
        14100: {
            percent: 12,
            amount: 1410
        },
        53700: {
            percent: 22,
            amount: 6162
        },
        85500: {
            percent: 24,
            amount: 13158
        },
        163300: {
            percent: 32,
            amount: 31830
        },
        207350: {
            percent: 35,
            amount: 45926
        },
        518400: {
            percent: 37,
            amount: 154793.5
        }
    }
}

const CONSTANTS = {
    genders: genders,
    defaultGender: defaultGender,
    currencies: currencies,
    defaultCurrency: defaultCurrency,
    stateList,
    fillingStatusList,
    selfEmploymentTaxRate,
    federalTaxData,
    AUTH_TOKEN: "authToken",
    AS_USER: "asUser"
}

export default CONSTANTS;
