import axiosInstance from "./AxiosService";
import { currentYearStartUTC, todayEndUTC } from "../shared/DateUtils";
import { toastSuccess } from '../shared/ToastifyService';
import firebase from "firebase/app";
import { storageRef } from "../firebase";

/**
 * Creates (or updates if already presented) application specific user entry based on the Firebase authenticated user.
 *
 * @param {*} user Firebase authenticated user instance 
 * @param {*} additionalData additional data if any to store to Firestore
 * @returns Application specific user entity
 * @memberof RestService
 */
export const createOrUpdateUser = async (user, additionalData) => {
    if (!user) return;

    const { uid, email, displayName, photoURL } = user;
    const providerId = !!user ? user.providerData[0].providerId : null;
    const isEmail = providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID;

    const theBody = {
        id: uid,
        email,
        isEmail: isEmail,
        username: displayName || additionalData?.username,
        photoURL
    };
    if (!!additionalData?.gender) {
        theBody.gender = additionalData.gender
    }
    if (!!additionalData?.subscription) {
        theBody.subscription = additionalData.subscription
    }
    if (!!additionalData?.giftId) {
        theBody.giftId = additionalData.giftId
    }

    if (!!additionalData?.userType) {
        theBody.userType = additionalData.userType
    }

    try {
        let response = await axiosInstance.post("/webApi/api/v1/users/createOrUpdate", theBody);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const updateProfile = async (profileData) => {
    let response = await axiosInstance.put(`/webApi/api/v1/users/updateProfile`, profileData);
    let result = response.data;
    toastSuccess("Profile successfully updated");
    return result;
};

export const updateUserData = async (profileData) => {
    let response = await axiosInstance.put(`/webApi/api/v1/users/updateUserData`, profileData);
    let result = response.data;
    toastSuccess("Profile successfully updated");
    return result;
};

export const updateAvatar = async (user, data) => {
    try {
        const metadata = {
            contentType: 'image/jpeg'
        };
        const uploadTask = storageRef.child('images/' + user.id).put(data, metadata);
        // await uploadTask.on("state_changed");
        // const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
    
        // const { email, username, gender, currency } = user;
        // let response = await axiosInstance.put(`/webApi/api/v1/users/updateProfile`,
        // {
        //     email,
        //     username,
        //     gender: gender ?? 'NB',
        //     currency,
        //     photoURL: downloadURL
        // });
        // const result = response.data;
        // toastSuccess("Photo successfully updated");
        // return result;
        return uploadTask;
    } catch (err) {
        console.log(err);
    }
};

function arrayQuery(array, paramName) {
    if (!array || array.length === 0) {
        return "";
    }
    return `&${paramName}=${array.join(",")}`;
    // return array.map(value => {
    //     return `&${paramName}[]=${value}`;
    // }).join('');
}

export const getUsers = async () => {
    let response = await axiosInstance.get(`/webApi/api/v1/users`);
    let result = response.data;
    return result;
};

export const getUser = async (id, userType) => {
    let response = await axiosInstance.get(`/webApi/api/v1/users/getGiftUser/${id}/${userType}`);
    let result = response.data;
    return result;
};

export const getIncomesResult = async (from, to, methods, contacts, sources) => {
    const theFrom = !!from ? from : currentYearStartUTC();
    const theTo = !!to ? to : todayEndUTC();
    const methodsQuery = arrayQuery(methods, "paymentMethods");
    const contactsQuery = arrayQuery(contacts, "contacts");
    const sourcesQuery = arrayQuery(sources, "sources");
    const timezoneOffset = new Date().getTimezoneOffset();
    try {
        let response = await axiosInstance.get(`/webApi/api/v1/incomes?from=${theFrom}&to=${theTo}&timezoneOffset=${timezoneOffset}${methodsQuery}${contactsQuery}${sourcesQuery}`);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const getIncomes = async (from, to) => {
    const theFrom = !!from ? from : currentYearStartUTC();
    const theTo = !!to ? to : todayEndUTC();
    try {
        let response = await axiosInstance.get(`/webApi/api/v1/incomes/total?from=${theFrom}&to=${theTo}`);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const exportIncomes = async () => {
    try {
        let response = await axiosInstance({
            url: `/webApi/api/v1/incomes/csv`,
            method: 'GET',
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', 'incomes.csv');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        handleError(error);
    }
};

export const exportUsers = async () => {
    try {
        let response = await axiosInstance({
            url: `/webApi/api/v1/users/csv`,
            method: 'GET',
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', 'users.csv');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        handleError(error);
    }
};

export const createIncome = async (incomeData) => {
    try {
        let response = await axiosInstance.post("/webApi/api/v1/incomes", incomeData);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const updateIncome = async (incomeId, incomeData) => {
    try {
        let response = await axiosInstance.put(`/webApi/api/v1/incomes/${incomeId}`, incomeData);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const deleteIncome = async (incomeId) => {
    try {
        await axiosInstance.delete(`/webApi/api/v1/incomes/${incomeId}`);
        return true;
    } catch (error) {
        handleError(error);
        return false;
    }
};

export const createProperty = async (collection, name) => {
    const validCollection = ["contacts", "sources", "paymentMethods"].includes(collection);
    if (!validCollection) {
        return;
    }

    try {
        let response = await axiosInstance.post(`/webApi/api/v1/${collection}`, { "name": name });
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const updatePropertyName = async (collection, id, name) => {
    const validCollection = ["contacts", "sources", "paymentMethods"].includes(collection);
    if (!validCollection) {
        return;
    }

    try {
        let response = await axiosInstance.put(`/webApi/api/v1/${collection}/${id}`, { "name": name });
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const getSourceId = async (contactId) => {
    try {
        let response = await axiosInstance.get(`/webApi/api/v1/incomes/source/${contactId}`);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const getBraintreeToken = async () => {
    try {
        let response = await axiosInstance.get(`/webApi/api/v1/subscription/getToken`);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const executePayment = async (data) => {
    try {
        let response = await axiosInstance.post(`/webApi/api/v1/subscription/execute-payment`, data);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

export const sendEmail = async (data) => {
    try {
        let response = await axiosInstance.post(`/webApi/api/v1/users/sendEmail`, data);
        let result = response.data;
        return result;
    } catch (error) {
        handleError(error);
    }
};

const handleError = (error) => {
    console.error("Error calling REST API endpoint", error);
};
