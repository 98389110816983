import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortTable = (props) => {
    const {
        currentProperty,
        property,
        ascending,
        doSort
    } = props;

    return (
        <div className="ml-2 float-left">
            {currentProperty !== property
                ? <FontAwesomeIcon icon={['fas', 'sort']} className="action-icon" onClick={() => doSort(property)} />
                : (ascending
                    ? <FontAwesomeIcon icon={['fas', 'sort-down']} className="action-icon" onClick={() => doSort(property)} />
                    : <FontAwesomeIcon icon={['fas', 'sort-up']} className="action-icon" onClick={() => doSort(property)} />
                )
            }
        </div>
    );
}

export default SortTable;
