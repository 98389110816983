import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoText from '../logo-text.svg';
import logoIcon from '../logo-icon.svg';
import './IntroPanel.css';

const IntroPanel = () => {

    return (
        <div className="intro-panel vh-100">
            <img src={logoText} className="logo-text" alt={process.env.REACT_APP_READABLE_NAME} />
            <img src={logoIcon} className="logo-icon intro-md-hide" alt="" />
            <div className="intro-md-hide" style={{ height: "7.5%" }}></div>

            <div className="text-left ml-5 font-white intro-md-hide">
                <FontAwesomeIcon icon={['fas', 'chart-line']} size="2x" style={{ color: '#5085ED' }} />&nbsp;
                <b className="font-22">Track your income, effortlessly</b>
                <span className="font-16 mt-2 d-block">&bull;&nbsp;Easy-to-use interactive chart</span>
                <span className="font-16 mt-2 mb-5 d-block">&bull;&nbsp;View trends over time</span>

                <FontAwesomeIcon icon={['fas', 'shield-alt']} size="2x" style={{ color: 'gold' }} className="colorized-icon" />&nbsp;
                <b className="font-22">Serious data and privacy protection</b>
                <span className="font-16 mt-2 d-block">&bull;&nbsp;No geo-location tracking</span>
                <span className="font-16 mt-2 d-block">&bull;&nbsp;256-bit encryption</span>
                <span className="font-16 mt-2 mb-5 d-block">&bull;&nbsp;Account anonymity</span>

                <FontAwesomeIcon icon={['fas', 'chart-pie']} size="2x" style={{ color: '#69ACE0' }} className="colorized-icon" />&nbsp;
                <b className="font-22">Invest in your future</b>
                <span className="font-16 mt-2 d-block">&bull;&nbsp;Watch your business grow</span>
                <span className="font-16 mt-2 d-block">&bull;&nbsp;Create an account or log-in today!</span>
            </div>
        </div>
    );
};

export default IntroPanel;
