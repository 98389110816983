import React from "react";
import Application from "./components/Application";
import UserProvider from "./providers/UserProvider";
import { ToastContainer } from 'react-toastify';
import './App.css';

function App() {
  return (
    <UserProvider>
      <Application />
      <ToastContainer />
    </UserProvider>
  );
}

export default App;
