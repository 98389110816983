import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartLine,
  faLongArrowAltDown, faLongArrowAltUp,
  faChartPie, faShieldAlt, faCircle,
  faCalendarAlt, faTrashAlt, faSlidersH, faFileExport,
  faSort, faSortUp, faSortDown,
  faDollarSign, faEuroSign, faPoundSign,
  faExclamationTriangle,
  faEye,
  faHeart, faHeartbeat
} from '@fortawesome/free-solid-svg-icons';
import {
  faGoogle, faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
  faEdit
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faChartLine,
  faGoogle, faTwitter,
  faLongArrowAltDown, faLongArrowAltUp,
  faChartPie, faShieldAlt, faCircle,
  faCalendarAlt, faTrashAlt, faEdit, faSlidersH, faFileExport,
  faSort, faSortUp, faSortDown,
  faDollarSign, faEuroSign, faPoundSign,
  faExclamationTriangle,
  faEye,
  faHeart, faHeartbeat
);
