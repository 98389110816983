import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "@reach/router";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { auth } from "../firebase";
import logo from "../logo-horizontal.svg";
import { UserContext } from "../providers/UserProvider";
import "./NavigationBar.css";

const NavigationBar = (props) => {
  const { callback } = props;

  const { user, setAsUser } = useContext(UserContext);
  const { photoURL, username } = user;
  const [profileURL, setProfileURL] = useState(photoURL ?? "/noAvatar.jpg");

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setProfileURL(user.photoURL ?? "/noAvatar.jpg");
  }, [user]);

  useEffect(() => {
    if (!!callback) {
      callback(isOpen);
    }
  }, [isOpen]);

  return (
    <Navbar
      light
      fixed="top"
      expand="md"
      className="app-navbar-55 custom-navbar"
    >
      <NavbarBrand
        onClick={() => {
          navigate(user.isAdmin ? "/dashboard/users" : "/dashboard");
        }}
      >
        <img
          src={logo}
          className="navbar-logo"
          alt={process.env.REACT_APP_READABLE_NAME}
        />
      </NavbarBrand>

      {/* <NavbarToggler onClick={toggle} /> */}
      {/* <div className="d-md-none">
                <div
                    style={{
                            background: `url(${photoURL || '/noAvatar.jpg'})  no-repeat center center`,
                            backgroundSize: "cover"
                        }}
                    className="user-photo mr-2"
                    onClick={toggle} >
                </div>
            </div> */}

      {/* <Collapse isOpen={isOpen} navbar> */}
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav className="mr-1">
            <div className="form-inline">
              <img
                src={profileURL || "/noAvatar.jpg"}
                onError={() => setProfileURL("/noAvatar.jpg")}
                className="user-photo mr-2"
              ></img>
              <b className="font-16 d-none d-md-block menu-text with-caret">
                {username}
              </b>
            </div>
          </DropdownToggle>
          <DropdownMenu className="main-dropdown" right>
            {/*
                                user.isAdmin &&
                                    <DropdownItem onClick={() => { setAsUser(null);  navigate('/dashboard') }}>
                                        My Dashboard
                                    </DropdownItem>
                            */}
            <DropdownItem
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Dashboard
            </DropdownItem>
            {user.isAdmin ? (
              <DropdownItem
                onClick={() => {
                  navigate("/dashboard/users");
                }}
              >
                Users
              </DropdownItem>
            ) : null}
            {/*
                                !user.isAdmin
                                    ? <DropdownItem onClick={() => { navigate('/subscription/user') }}>
                                        Subcription
                                    </DropdownItem>
                                    :
                                    null
                            */}
            <DropdownItem
              onClick={() => {
                navigate("/goals");
              }}
            >
              Goals
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate("/settings");
              }}
            >
              Settings
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                auth.signOut();
                navigate("/home");
              }}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/* </Collapse> */}
    </Navbar>
  );
};

export default NavigationBar;
