import React from "react";
import { CurrencyHandler, PercentHandler } from "./HelperHandlers";

const StatisticCard = (props) => {
    const {
        label,
        statisticData,
        total,
        clickable, callback, period
    } = props;

    return (
        <div className={"card mb-2 " + (clickable ? "statitics-card-clickable" : "statitics-card ")}
            onClick={event => clickable ? callback(period) : true}>
            <div className="card-body">
                <div className="row mx-2 mt-n2 mb-2">
                    <div className="font-22 font-black">
                        <b><CurrencyHandler amount={statisticData?.sum} /></b>
                    </div>
                    <div className={"font-16 ml-auto " + (total ? "d-none" : "")}>
                        <PercentHandler value={statisticData?.diffPercentage} colored />
                    </div>
                </div>
                <div className="row mx-2">
                    <div className="font-16 font-black">{label}</div>
                    <div className={"font-16 ml-auto " + (total ? "d-none" : "")}>
                        <CurrencyHandler amount={statisticData?.diff} signed colored />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default StatisticCard;
