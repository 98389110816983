import React, { useContext, useState, useEffect } from "react";
import CurrencyFormat from 'react-currency-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from "../providers/UserProvider";
import CONSTANTS from "../shared/Constants";
import moment from "moment";

export const DateRange = (props) => {
    const {
        from,
        to
    } = props;

    return (
        <div className="d-inline ml-3 font-lightgray">
            {
                !!from && !!to
                    ? `(${moment(from).format('M-D-YYYY')} - ${moment(to).format('M-D-YYYY')})`
                    : ""
            }
        </div>
    )
};

export const CurrencyIcon = (props) => {
    const {
        color
    } = props;

    const { user } = useContext(UserContext);

    const [iconClasses, setIconClasses] = useState(CONSTANTS.defaultCurrency.iconClasses);
    useEffect(() => {
        const theKey = user.currency || CONSTANTS.defaultCurrency.key
        const theCurrency = CONSTANTS.currencies.find(next => next.key === theKey) || CONSTANTS.defaultCurrency;
        setIconClasses(theCurrency.iconClasses);
    }, [user]);

    return (
        <FontAwesomeIcon icon={iconClasses} style={{ color: color || 'black' }} />
    )
};

export const CurrencyHandler = (props) => {
    const {
        forUser,
        currency
    } = props;

    const { user, asUser } = useContext(UserContext);

    const [symbol, setSymbol] = useState("");
    useEffect(() => {
        const theKey = forUser?.currency || asUser?.currency || user.currency || CONSTANTS.defaultCurrency.key
        const theCurrency = CONSTANTS.currencies.find(next => next.key === theKey) || CONSTANTS.defaultCurrency;
        setSymbol(theCurrency.symbol);
    }, [user, asUser, forUser]);

    useEffect(() => {
        const theKey = currency || forUser?.currency || asUser?.currency || user.currency || CONSTANTS.defaultCurrency.key;
        const theCurrency = CONSTANTS.currencies.find(next => next.key === theKey) || CONSTANTS.defaultCurrency;
        setSymbol(theCurrency.symbol);
    }, [currency]);

    return (
        <div className="d-inline">
            {
                !!props.amount
                    ? <CurrencyFormat value={Math.abs(props.amount / 100)} displayType={'text'}
                        thousandSeparator={true} decimalScale={2} fixedDecimalScale
                        prefix={
                            `${props.signed ? (props.amount > 0 ? "+" : "-") : ""}${symbol}`
                        }
                        renderText={value => <div className={"d-inline " +
                            (props.colored && props.amount > 0 ? "font-green" : "") +
                            (props.colored && props.amount < 0 ? "font-red" : "")
                        }>{value}</div>
                        }
                    />
                    : (props.amount === 0 ? `${symbol}0.00` : "-")
            }
        </div>
    )
};

export const PercentHandler = (props) => {
    return (
        <div>
            {
                !!props.value
                    ?
                    <div className="d-inline">
                        {
                            props.value > 0
                                ? <FontAwesomeIcon icon={['fas', 'long-arrow-alt-up']} className="font-green d-inline" />
                                : <FontAwesomeIcon icon={['fas', 'long-arrow-alt-down']} className="font-red d-inline" />
                        }
                        <CurrencyFormat value={props.value} displayType={'text'} suffix={'%'}
                            renderText={value => <div className={
                                "d-inline " +
                                (props.colored && props.value > 0 ? "font-green" : "") +
                                (props.colored && props.value < 0 ? "font-red" : "")
                            }>{value}</div>
                            }
                        />
                    </div>
                    : (props.value === 0 ? "0%" : "-")
            }
        </div>
    )
};

export const PropertyHandler = (props) => {
    const [property, setProperty] = useState([]);
    useEffect(() => {
        const property = props.collection?.find(item => item.id === props.propertyId);
        setProperty(property);
    });

    return (
        <div>{!!property ? property.name : ""}</div>
    )
};
