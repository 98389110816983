import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

export const signInWithTwitter = () => {
    auth.signInWithPopup(twitterProvider);
};

export const reauthenticate = (currentPassword) => {
    const user = auth.currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
}

export const storage = firebase.storage();
export const storageRef = storage.ref();

export const changePassword = (currentPassword, newPassword, done, error) => {
    reauthenticate(currentPassword).then(() => {
        var user = auth.currentUser;
        user.updatePassword(newPassword)
            .then(() => {
                done();
            })
            .catch((e) => { error(e); });
    })
        .catch((e) => { error(e); });
}

export const changeEmail = (email, done, error) => {
    var user = auth.currentUser;
    user.updateEmail(email)
        .then(() => {
            done();
        })
        .catch((e) => { error(e); });
}

export const changeProfile = (data, done, error) => {
    var user = auth.currentUser;
    user.updateProfile(data)
        .then(() => {
            done();
        })
        .catch((e) => { error(e); });
}

export const firestore = firebase.firestore();
