import moment from 'moment';

export const YEAR_MILLIS = 1000 * 60 * 60 * 24 * 366 - 1;
export const DAY_MILLIS = 1000 * 60 * 60 * 24;
export const WEEK_MILLIS = 1000 * 60 * 60 * 24 * 7;


export const formatDate = function (dateUTC) {
    var date = new Date(dateUTC);
    return moment(date).format('M-D-YYYY');
}

export const currentMonthStartUTC = (dateUTC) => {
    return moment(dateUTC).startOf('month').toDate().getTime();
}

export const monthStartUTC = (dateUTC) => {
    const date = new Date(dateUTC);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    return Date.UTC(year, month, 1);
}

export const currentYearStartUTC = function () {
    return moment(dateUTC).startOf('year').toDate().getTime();
}

export const yearStartUTCFor = (dateUTC) => {
    const date = new Date(dateUTC);
    const year = date.getUTCFullYear();
    return Date.UTC(year, 0, 1);
}

export const todayStartUTC = function () {
    return moment(dateUTC).startOf('day').toDate().getTime();
}

export const todayEndUTC = function () {
    return moment(dateUTC).endOf('day').toDate().getTime();
}

export const dayStartUTC = function (dateUTC) {
    return moment(dateUTC).startOf('day').toDate().getTime();
}

export const minusDay = function (dateUTC) {
    const date = new Date(dateUTC);
    date.setUTCDate(date.getUTCDate() - 1);
    return date.getTime();
}

export const previousWeekEnd = function (dateUTC) {
    const date = new Date(dateUTC);
    const weekDay = date.getUTCDay() || 7;
    date.setUTCDate(date.getUTCDate() - weekDay + 1);
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime() - 1;
}

export const previousMonthEnd = function (dateUTC) {
    const date = new Date(dateUTC);
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime() - 1;
}

export const previousYearEnd = function (dateUTC) {
    const date = new Date(dateUTC);
    date.setUTCMonth(0);
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime() - 1;
}

export const dayEndUTC = function (dateUTC) {
    return moment(dateUTC).endOf('day').toDate().getTime();
}

export const monthEnd = function (dateUTC) {
    return moment(dateUTC).endOf('month').toDate().getTime();
}

export const yearEnd = function (dateUTC) {
    return moment(dateUTC).endOf('year').toDate().getTime();
}

export const daysInMonth = function (dateUTC) {
    const date = new Date(dateUTC);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    return new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
}
